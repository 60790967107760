.commingsoon {
  padding-top: 30vh;
  width: 100vh;
  color: #eee;
  font-size: 10rem;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.backhome {
  color: #fff;
  font-size: 4rem;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 30vw;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.backhome:hover {
  color: #fff;
}
.project-page-title {
  color: #fff;
  margin: 0;
  font-size: 5rem;
  user-select: none;
  text-align: center;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.project-gallery {
  height: max-content;
  width: 100%;
  margin-top: 3rem;
  flex-wrap: wrap;
  display: inline-flex;
}
.project-prview {
  width: 20%;
  height: 20vw;
  display: flex;
  cursor: pointer;
  align-items: flex-end;
  border: black 1px solid;
  background-size: cover;
  justify-content: center;
  background-color: #333;
  background-position: center;
  background-repeat: no-repeat;
}
.project-prview h3 {
  color: #fff;
  font-size: 3rem;
  padding-bottom: 7vw;
  position: absolute;
}
.projec-info {
  padding: 1rem;
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  line-height: 21px;
  font-size: 18px;
  font-weight: 300;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
}
.project-preview-background {
  width: 100%;
  z-index: 0;
  box-shadow: 0 -20px 70px 80px rgba(0, 0, 0, 0.9);
}
.project-prview .visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}
.project-prview .hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
.tag-preview-list,
.project-preview-title {
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.tag-preview-list .entity-tag {
  margin: 1px;
  zoom: 80%;
}
.project-details {
  width: 60vw;
  height: 95vh;
  overflow: hidden;
}
.project-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-details-list {
  width: 100%;
  height: 95vh;
  overflow-y: auto;
}

.project-details-btn {
  top: 50vh;
  font-size: 5rem;
  cursor: pointer;
  position: fixed;
}
.project-previous {
  left: 1rem;
}
.project-next {
  right: 1rem;
}
.project-prview video {
  position: absolute;
}
/* Will be deleted */

.project-section-title {
  color: #fff;
  font-size: 2rem;
  text-align: center;
}
.dummy-project {
  color: #fff;
  display: flex;
  width: 70vw;
  padding-bottom: 2rem;
  font-size: 1.5rem;
  margin: 0 auto;
  justify-content: center;
}
.dummy-project img {
  padding: 2rem;
  height: 13rem;
}
.details-wrapper {
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 100;
  scroll-behavior: unset;
}
.tag-select-bar {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
