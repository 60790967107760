.contact-form {
  display: flex;
  padding: 1rem 5rem;
  width: max-content;
  margin: 1rem auto;
  border-radius: 2rem;
  flex-direction: column;
  text-transform: uppercase;
  background-color: #242537;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.contact-form-title {
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  gap: 1rem;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.contact-form-body {
  width: max-content;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
}
.contact-form-row {
  display: flex;
  justify-content: space-between;
}
.contact-input-field {
  display: flex;
  width: max-content;
  margin: 1rem;
  flex-direction: column;
}
.contact-input-field span {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  width: 15rem;
}

.contact-input-field input,
.contact-input-field input:active {
  color: #fff;
  width: 20vw;
  margin: 0.5rem 0;
  background: inherit;
  border: none;
  border-bottom: 2px solid #fff;
}
.contact-input-field input:focus {
  outline: none;
}
.contact-form-body textarea {
  width: 100%;
  height: 30vh;
  padding: 1rem;
  resize: none;
  margin: 0.5rem 0;
  border-radius: 1rem;
  font-size: 1.2rem;
}
.contact-form-body textarea:focus {
  outline: none;
}

@media screen and (max-width: 600px) {
  .contact-form {
    width: 100%;
    padding: 1rem !important;
  }
  .contact-form-body {
    margin: 0 auto;
  }
  .contact-submit {
    width: max-content !important;
    margin: 1rem auto;
  }
  .contact-input-field input {
    width: 60vw;
  }
  .contact-form-row {
    display: flex;
    flex-direction: column !important;
  }
}
.contact-submit {
  float: right;
  width: 15rem;
  padding: 1.5rem;
  cursor: pointer;
  margin-top: 1rem;
  margin-left: auto;
  border-radius: 3rem;
  background-color: #222;
}
.contact-submit:hover {
  color: #222;
  background-color: #fff;
  transition: linear 0.3s;
}
