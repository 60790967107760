.artWork {
  overflow: auto;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 4rem;
}
.artwork-toolbar {
  top: 1rem;
  left: 1rem;
  z-index: 1001;
  position: fixed;
}
.artwork-backward {
  width: 48px;
  height: 48px;
  color: aliceblue;
  background-color: #444;
  padding: 1rem;
  border-radius: 20%;
  cursor: pointer;
  user-select: none;
}
.artwork-backward:hover {
  font-size: 120%;
}
