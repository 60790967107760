.portfolio-cover {
  height: 50vh;
  color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.portfolio-cover h1 {
  font-size: 5rem;
  font-weight: 400;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  margin-bottom: 1rem;
}
.portfolio-background {
  width: 100%;
  height: 50vh;
  position: absolute;
  filter: blur(2px);
  background-size: cover;
  z-index: -1;
  background-repeat: no-repeat;
}
.portfolio-content-body {
  display: flex;
  min-height: 30vh;
  flex-direction: column;
}