.app-footer {
  color: #eee;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  height: max-content;
  padding: 5rem 3rem;
  background: #333;
}
.copyright {
  display: block;
  padding-top: 3rem;
  text-align: center;
}
