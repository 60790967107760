.navigation {
  display: flex;
  height: 5rem;
  max-width: 100%;
  align-items: center;
  background-color: #000;
}
.nav-header {
  display: flex;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
}
.nav-banner {
  display: inline;
  margin-right: 1rem;
  position: relative;
}
.logo-img {
  width: 3rem;
  height: 3rem;
}
