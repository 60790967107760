.file-list-reorder {
  padding: 1rem;
  list-style-type: none;
}
.file-row-item {
  margin: 1rem 0;
  cursor: pointer;
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  justify-content: space-between;
  background-color: #222;
}
.row-item-delete {
  margin-left: auto;
  float: right;
}
