.dropbox {
  width: 400px;
  height: 300px;
  display: flex;
  cursor: pointer;
  text-align: center;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  border: 0.2rem dashed #c9c7c7;
}

.dropbox:hover {
  transition: linear 0.3s;
  border: 0.2rem dashed #54a5f1;
}
