.entity-tag {
  margin: 0.5rem;
  width: max-content;
  font-weight: 100;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  border-radius: 3rem;
  display: flex;
  user-select: none;
  cursor: pointer;
  transition: linear 0.2s;
  font-weight: 600;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.delete-icon {
  margin-left: 1rem;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 700;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
.delete-icon:hover {
  font-size: 1.1rem;
}
