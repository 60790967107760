#radix-2-trigger-0,
#radix-2-trigger-1 {
  display: none;
}
.admin-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.admin-header {
  color: #eee;
  padding: 1rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.admin-header h1 {
  font-style: italic;
  font-size: 4rem;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.admin-main {
  color: white;
  display: flex;
  padding: 1rem 0;
  width: 100vw;
  margin: 0 auto;
  justify-content: center;
}
.admin-main section {
  width: 80vw;
}
.add-panel {
  display: flex;
}
.add-panel input {
  height: inherit;
}

.tag-manager {
  padding: 3rem;
  user-select: none;
  height: max-content;
}
.add-tag {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 1rem;
  background-color: #434343;
}
.preview-tag {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tag-delete-btn {
  font-size: 1rem;
  height: 2.5rem;
  color: #eeeeee;
  border-radius: 3rem;
  cursor: pointer;
  font-weight: 600;
  background-color: #434343;
}
.tag-item {
  display: flex;
  width: 100%;
  margin: 0.3rem auto;
  padding: 0.1rem 0.5rem;
  align-items: center;
  border-radius: 3rem;
  background-color: rgba(43, 43, 43, 0.4);
}
.tag-item-list {
  padding: 1rem;
  overflow-y: auto;
}
.tag-item-btn-group {
  margin-left: auto;
}
.upload-img-panel {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}
.contact-message-list {
  min-width: 34rem;
  height: 30rem;
  padding: 2rem;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  background-color: #434343;
}
.contact-message-item {
  display: flex;
  width: 80%;
  height: 40vh;
  min-width: 30rem;
  min-height: 20rem;
  padding: 1rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #555;
  box-shadow: 1rem 1rem 2rem #333;
}
.contact-message-item p {
  color: #000;
  width: 28rem;
  height: 15rem;
  margin: 1rem;
  padding: 0.5rem;
  overflow: auto;
  background-color: #eee;
  white-space: pre-line;
}
.image-list-container {
  margin: 2rem;
  padding: 1rem;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #434343;
}
.image-list {
  width: 100%;
  height: 100%;
  padding: 0;
  list-style: none;
  overflow-y: auto;
}
.image-list-item {
  margin: 1rem;
  padding: 1rem;
  background-color: #555;
}
.image-preview {
  width: 100%;
  overflow: auto;
}
.upload-tab {
  background-color: transparent !important;
}