.halo {
  top: -50vh;
  left: -15vw;
  position: absolute;
}
.hero-name {
  font-size: 3rem;
  font-family: "Times New Roman", Times, serif;
}
svg {
  width: 70rem;
}
svg textPath {
  font-size: 2rem;
  font-family: sans-serif;
  text-transform: uppercase;
  letter-spacing: 20px;
  fill: #fff;
  background: #eee;
  font-family: "Times New Roman", Times, serif;
}
.home-header {
  display: flex;
  height: 100vh;
  user-select: none;
  justify-content: center;
}
.home-menu {
  max-width: 100%;
}
.author-name {
  margin: 0 0.5rem;
  font-family: damion, cursive;
}
.menu .item {
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
}
.text-effect {
  top: 30vh;
  position: absolute;
  font-size: 12rem;
  color: #fff;
  text-align: center;
  line-height: 10rem;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
@media screen and (max-width: 600px) {
  .home-menu {
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
  .nav-item {
    zoom: 70%;
    padding: 1px;
    font-size: 1vw;
  }
  .text-effect {
    font-size: 15vw;
    line-height: 15vw;
    font-weight: 700;
    letter-spacing: 1px;
  }
  .portfolio-body {
    display: flex;
    flex-direction: column;
  }
  .block-body {
    min-width: 90vw;
  }
  .about-container {
    padding-top: 2vh !important;
  }
  .intro-container {
    display: flex;
    width: 100%;
    margin: 0;
    flex-direction: column;
  }
  .about-content {
    width: 100% !important;
    margin: 0 auto !important;
    padding: 1rem !important;
  }
  .left-board,
  .right-board {
    min-width: 100% !important;
    justify-content: center;
  }
}
.right-board {
  overflow: "hidden";
  z-index: 1;
  width: "60vw";
}
.text-effect h1,
.text-effect span {
  font-size: 5rem;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.banner {
  padding: 1rem;
  top: 0;
  left: 0;
  cursor: pointer;
  position: fixed;
  z-index: 5;
}
.hello {
  height: 30vh;
  position: absolute;
  bottom: 4rem;
}
.main-container {
  color: #eee;
  font-size: 1.5rem;
  user-select: none;
  font-family: "Courier New", Courier, monospace;
}
.about-title {
  font-size: 4rem;
  font-weight: 500;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.hero {
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.about-container {
  padding-top: 6rem;
}
.intro-container {
  margin: 0 auto;
  max-width: 80vw;
  height: max-content;
  display: flex;
  position: relative;
}
.astronaut {
  top: 130vh;
  right: -3rem;
  zoom: 0.8;
  z-index: -1;
  position: absolute;
}
.info {
  margin-top: 2rem;
}
.left-board {
  width: 15vw;
  min-width: 20rem;
}
.about-content {
  margin-left: 5rem;
  width: 50vw;
  height: max-content;
  padding: 1rem 5rem;
  background-color: rgba(0, 0, 0, 0.4);
}
.nav-item {
  font-size: 2rem;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.software {
  width: 50vw;
  display: flex;
  flex-wrap: wrap;
  zoom: 0.8;
  margin-bottom: 2rem;
}
.software-level {
  width: 5rem;
  display: flex;
  margin: 1rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.software-logo {
  height: 3vw;
  margin: 1rem;
}
.social-link {
  width: 100%;
  zoom: 150%;
}
.social-media {
  display: flex;
}
.social-logo {
  cursor: pointer;
  width: 100%;
}
/**/
.card {
  font-size: 164px;
  width: 300px;
  height: 430px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 20px;
  box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),
    0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),
    0 0 16px hsl(0deg 0% 0% / 0.075);
  transform-origin: 10% 60%;
}
.portfolio-container {
  padding-bottom: 2rem;
  z-index: 2;
}
.portfolio-title {
  text-align: center;
  font-size: 5rem;
  font-weight: 500;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.card-list-wrapper {
  display: flex;
  height: 430px;
  width: max-content;
  margin: 0 auto;
  zoom: 70%;
}
.card-container {
  overflow: hidden;
  display: flex;
  width: 500px;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 20px;
  margin-bottom: -120px;
}

.splash {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  clip-path: path(
    "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z"
  );
}
.portfolio-body {
  display: flex;
  margin: 0 auto;
  width: max-content;
}
.block-body {
  display: flex;
  width: 23vw;
  height: 25vh;
  margin: 0.5rem;
  color: #2f3040;
  overflow: hidden;
  text-align: center;
  border-radius: 1rem;
  justify-content: center;
  background-color: #333;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.block-cover {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-cover:hover {
  color: #eee;
  transition: linear 0.4s;
  background-color: rgba(0, 0, 0, 0.7);
}
.block-name {
  font-size: 3rem;
}
.project-container {
  display: flex;
  width: 100%;
}
.project-starship {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: fliph; /*IE*/
}
.project-title {
  font-size: 5rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.project-body {
  padding: 1rem 0;
  width: 100%;
}
.project-discover {
  padding-top: 12%;
  float: right;
  color: #242537;
  font-size: 3rem;
  cursor: pointer;
  font-weight: 500;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
  text-transform: uppercase;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.project-discover:hover {
  color: #eee;
  transition: linear 0.3s;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.project-poster {
  width: 90%;
  height: 30vh;
  padding: 2rem;
  border-radius: 2rem;
  margin: 0 auto;
  background-color: #333;
  background-size: cover;
  background-repeat: no-repeat;
}
.demo-reel {
  width: 90vw;
  height: 70vh;
  margin: 1.5rem auto;
  display: flex;
  overflow: hidden;
  justify-content: center;
}
#player {
  top: 0;
  position: absolute;
}
