.slides-container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  overflow: hidden;
}
.slide-image {
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
}
.next,
.prev {
  top: 0;
  color: white;
  position: fixed;
  background: transparent;
  width: 40px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
  z-index: 0;
}
.next {
  right: 0;
}
.prev {
  left: 0;
}
